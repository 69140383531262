import React, { ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import MaterialTable, { Column } from "material-table";
import { GET_ALL_POSITION_CATEGORY_MARGINS } from "router/url";
import tableIcons from "components/Table/icons";
import { useFetch } from "utils/hooks/useFetch";
import { GlobalContext } from "../../globalContext/GlobalContext";

const SUM_ROW_POSITION_NAME = "TOTAL";

const sortWithoutTotal =
  (propertyName: string): ((item1: IPositionCategoryMargin, item2: IPositionCategoryMargin) => number) =>
  (item1: IPositionCategoryMargin, item2: IPositionCategoryMargin): number => {
    if (item1.positionCategory === SUM_ROW_POSITION_NAME || item2.positionCategory === SUM_ROW_POSITION_NAME) return 1;

    const property1 = item1[propertyName];
    const property2 = item2[propertyName];

    if (typeof property1 === "string" && typeof property2 === "string") {
      return property2.toLowerCase() < property1.toLowerCase() ? -1 : 1;
    }
    return (property2 as number) - (property1 as number);
  };

interface IPositionCategoryMargin extends Record<string, string | number> {
  positionCategory: string;
  employees: number;
  margins: number;
  totalClientRate: number;
  totalEmployeeRate: number;
  margin: number;
  averageMargin: number;
  marginToIncome: number;
}

const PositionCategoryMarginsList = (): ReactElement => {
  const columns: Column<IPositionCategoryMargin>[] = [
    {
      field: "positionCategory",
      title: "Position category",
      customSort: sortWithoutTotal("positionCategory"),
    },
    {
      field: "employees",
      title: "No of employees",
      align: "center",
      customSort: sortWithoutTotal("employees"),
    },
    {
      field: "margins",
      title: "No of margins",
      align: "center",
      customSort: sortWithoutTotal("margins"),
    },
    {
      field: "income",
      title: "Income (PLN)",
      align: "right",
      customSort: sortWithoutTotal("totalClientRate"),
      render: (item: IPositionCategoryMargin): string => {
        return item.totalClientRate.toLocaleString(undefined, { minimumFractionDigits: 2 });
      },
    },
    {
      field: "cost",
      title: "Cost (PLN)",
      align: "right",
      customSort: sortWithoutTotal("totalEmployeeRate"),
      render: (item: IPositionCategoryMargin): string => {
        return item.totalEmployeeRate.toLocaleString(undefined, { minimumFractionDigits: 2 });
      },
    },
    {
      field: "margin",
      title: "Margin (PLN)",
      align: "right",
      customSort: sortWithoutTotal("margin"),
      render: (item: IPositionCategoryMargin): string => {
        return item.margin.toLocaleString(undefined, { minimumFractionDigits: 2 });
      },
    },
    {
      field: "averageMargin",
      title: "Average margin (PLN)",
      align: "right",
      customSort: sortWithoutTotal("averageMargin"),
      render: (item: IPositionCategoryMargin): string => {
        return item.averageMargin.toLocaleString(undefined, { minimumFractionDigits: 2 });
      },
    },
    {
      field: "marginToIncome",
      title: "Margin To Income",
      align: "right",
      customSort: sortWithoutTotal("marginToIncome"),
      render: (item): string => {
        return (item.marginToIncome || 0).toLocaleString(undefined, { minimumFractionDigits: 2 }) + " %";
      },
    },
  ];

  const pageSizeOptions = [5, 10, 20, 50, 100];
  const { toggleLoading, fromQueryDate, toQueryDate } = useContext(GlobalContext);

  const [positionCategoryMargins, setPositionCategoryMargins] = useState<any>();
  const [marginsTableData, setMarginsTableData] = useState<any[]>([]);

  const getAllRates = useFetch();
  const [currentPage, setCurrentPage] = useState(0);
  const pageNumberQuery = `page=${currentPage}`;

  const getPositionCategoryMarginList = useCallback(
    async (number: number = 50) => {
      const size = `size=${number}`;
      toggleLoading(true);

      try {
        const response = await getAllRates("GET", `${GET_ALL_POSITION_CATEGORY_MARGINS}?${pageNumberQuery}&${size}`);
        setPositionCategoryMargins(response);
        toggleLoading(false);
      } catch (error) {
        toast.error("Margins list couldn't be loaded. Try again!");
      }
    },
    [toggleLoading, getAllRates, pageNumberQuery]
  );

  useEffect(() => {
    getPositionCategoryMarginList();
  }, [getPositionCategoryMarginList, fromQueryDate, toQueryDate]);

  useEffect(() => {
    const marginDataContent = positionCategoryMargins?.content;
    if (marginDataContent) {
      const totalRow = marginDataContent.reduce(
        (result: IPositionCategoryMargin, current: IPositionCategoryMargin): IPositionCategoryMargin => {
          result.employees += current.employees;
          result.margins += current.margins;
          result.totalClientRate += current.totalClientRate;
          result.totalEmployeeRate += current.totalEmployeeRate;
          result.margin += current.margin;
          result.averageMargin = result.employees ? result.margin / result.employees : 0;
          result.marginToIncome = 100 * (result.totalClientRate ? result.margin / result.totalClientRate : 0);
          return result;
        },
        {
          positionCategory: SUM_ROW_POSITION_NAME,
          employees: 0,
          margins: 0,
          totalClientRate: 0,
          totalEmployeeRate: 0,
          margin: 0,
          averageMargin: 0,
          marginToIncome: 0,
        }
      );
      setMarginsTableData([totalRow, ...marginDataContent]);
    }
  }, [positionCategoryMargins]);

  return positionCategoryMargins ? (
    <MaterialTable
      columns={columns}
      data={marginsTableData}
      page={currentPage}
      totalCount={positionCategoryMargins.totalElements}
      onChangePage={setCurrentPage}
      onChangeRowsPerPage={getPositionCategoryMarginList}
      icons={tableIcons}
      options={{
        emptyRowsWhenPaging: false,
        pageSize: positionCategoryMargins.size,
        search: false,
        exportButton: true,
        exportFileName: "PositionCategoryMargins",
        pageSizeOptions,
        rowStyle: (rowData: any, index: number) => ({
          backgroundColor: index ? (index % 2 ? "#FFF" : "#EEE") : "#CCC",
          fontWeight: index ? "normal" : "bold",
        }),
      }}
      title={"All position category margins"}
    />
  ) : (
    <></>
  );
};

export default PositionCategoryMarginsList;

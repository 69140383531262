import { ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { MonetizationOn, CloudDownload } from "@material-ui/icons";
import { IDictionary } from "globalContext/types";
import React, { useCallback, useState } from "react";
import { EXPORT_CLIENT_RATES, EXPORT_EMPLOYEE_RATES } from "router/url";
import { useFetch } from "utils/hooks/useFetch";
import { downloadFile } from "utils/downloadFile";
import CurrencyExchangeRateModal from "../CurrencyExchangeRateModal/CurrencyExchangeRateModal";
import jwt from "jwt-decode";

interface ISettingsMenuProps {
  handleCloseSettings: () => void;
  settingsMenuAnchor: HTMLElement | null;
  currencyOptions: IDictionary[];
}

const SettingsMenu = (props: ISettingsMenuProps) => {
  const { handleCloseSettings, settingsMenuAnchor, currencyOptions } = props;

  const [currencyExchangeModal, setCurrencyExchangeModal] = useState(false);
  const exportEmployeeRatesFetch = useFetch();
  const exportClientRatesFetch = useFetch();

  const handleCloseCurrencyExchangeModal = useCallback(() => {
    setCurrencyExchangeModal(false);
  }, []);

  const handleSelectCurrencyExchangeRate = useCallback(() => {
    handleCloseSettings();
    setCurrencyExchangeModal(true);
  }, []);

  const handleExportEmployeeRates = useCallback(async () => {
    const response = await exportEmployeeRatesFetch("GET", EXPORT_EMPLOYEE_RATES, {}, undefined, "blob");
    downloadFile(response, "EmployeeRates");
  }, []);

  const handleExportClientRates = useCallback(async () => {
    const response = await exportClientRatesFetch("GET", EXPORT_CLIENT_RATES, {}, undefined, "blob");
    downloadFile(response, "ClientRates");
  }, []);

  const parsedJwt: any = jwt(localStorage.getItem("dwhToken") as any);
  console.log("PARSED", parsedJwt);
  console.log("PARSED2", parsedJwt.preferred_username);

  const shouldShowExports = ["mikolaj.zwierzewski@jit.team", "goofy.dog@jit.fail"].includes(
    parsedJwt.preferred_username
  );

  return (
    <>
      <Menu
        id="settings-menu"
        anchorEl={settingsMenuAnchor}
        keepMounted
        open={Boolean(settingsMenuAnchor)}
        onClose={handleCloseSettings}
      >
        <MenuItem dense onClick={handleSelectCurrencyExchangeRate}>
          <ListItemIcon>
            <MonetizationOn />
          </ListItemIcon>
          <ListItemText primary="Currency exchange rates" />
        </MenuItem>
        {shouldShowExports && (
          <>
            <MenuItem dense onClick={handleExportEmployeeRates}>
              <ListItemIcon>
                <CloudDownload />
              </ListItemIcon>
              <ListItemText primary="Export employee rates" />
            </MenuItem>
            <MenuItem dense onClick={handleExportClientRates}>
              <ListItemIcon>
                <CloudDownload />
              </ListItemIcon>
              <ListItemText primary="Export client rates" />
            </MenuItem>
          </>
        )}
      </Menu>

      {currencyExchangeModal && (
        <CurrencyExchangeRateModal
          currencyOptions={currencyOptions}
          currencyExchangeModal={currencyExchangeModal}
          handleCloseCurrencyExchangeModal={handleCloseCurrencyExchangeModal}
        ></CurrencyExchangeRateModal>
      )}
    </>
  );
};

export default SettingsMenu;
